<template>
  <div>
    <div
      class="el-date-editor el-range-editor el-input__inner marR10 el-date-editor--datetimerange el-range-editor--mini"
      :class="{ 'is-disabled': disabled, 'is-active': active }"
    >
      <el-input
        ref="input_from"
        v-model="userInputForm"
        :disabled="disabled"
        :placeholder="startPlaceholder"
        @blur="handleBlurFrom"
        @focus="handleFocusFrom"
        @input="handleInputFrom"
        @change="handleInputChangeFrom"
      ></el-input>
      <div class="center">
        <span>至</span>
      </div>
      <el-input
        ref="input_to"
        v-model="userInputTo"
        :disabled="disabled"
        :placeholder="endPlaceholder"
        @blur="handleBlurTo"
        @focus="handleFocusTo"
        @input="handleInputTo"
        @change="handleInputChangeTo"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputNumberRange",
  props: {
    value: { required: true },

    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 精度参数
    precision: {
      type: Number,
      default: 0,
      validator(val) {
        return val >= 0 && val === parseInt(val, 10);
      },
    },
    startPlaceholder: {
      type: String,
      default: "最小值",
    },
    endPlaceholder: {
      type: String,
      default: "最大值",
    },
  },
  data() {
    return {
      active: false,
      userInputForm: null,
      userInputTo: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newV) {
        if (this.precision !== undefined) {
          const data = Array.isArray(newV) ? newV : [undefined, undefined];
          this.userInputForm = typeof data[0] === "number" ? data[0] : null;
          this.userInputTo = typeof data[1] === "number" ? data[1] : null;
        }
      },
      deep: true,
    },
  },

  methods: {
    // 根据精度保留数字
    toPrecision(num, precision) {
      if (precision === undefined) precision = 0;
      return parseFloat(
        Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
      );
    },

    handleBlurFrom(event) {
      this.$emit("blurfrom", event);
      this.active = false;
    },

    handleFocusFrom(event) {
      this.active = true;
      this.$emit("focusfrom", event);
    },

    handleBlurTo(event) {
      this.active = false;
      this.$emit("blurto", event);
    },

    handleFocusTo(event) {
      this.active = true;
      this.$emit("focusto", event);
    },

    handleInputFrom(value) {
      this.$emit("inputfrom", value);
      // this.userInputFrom = value
    },

    handleInputTo(value) {
      this.$emit("inputto", value);
      // this.userInputTo = value
    },

    // from输入框change事件
    handleInputChangeFrom(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === "") {
        this.$emit("input", [null, this.userInputTo]);
        this.$emit("changefrom", this.userInputForm);
        return;
      }

      // 初始化数字精度
      this.userInputForm = this.setPrecisionValue(value);

      // 如果from > to 将from值替换成to
      if (typeof this.userInputTo === "number") {
        this.userInputForm =
          parseFloat(this.userInputForm) <= parseFloat(this.userInputTo)
            ? this.userInputForm
            : this.userInputTo;
      }
      this.$emit("input", [this.userInputForm, this.userInputTo]);
      this.$emit("changefrom", this.userInputForm);
    },

    // to输入框change事件
    handleInputChangeTo(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === "") {
        this.$emit("input", [this.userInputForm, null]);
        this.$emit("changefrom", this.userInputTo);
        return;
      }

      // 初始化数字精度
      this.userInputTo = this.setPrecisionValue(value);

      if (typeof this.userInputForm === "number") {
        this.userInputTo =
          parseFloat(this.userInputTo) >= parseFloat(this.userInputForm)
            ? this.userInputTo
            : this.userInputForm;
      }
      this.$emit("input", [this.userInputForm, this.userInputTo]);
      this.$emit("changeto", this.userInputTo);
    },

    // 设置成精度数字
    setPrecisionValue(value) {
      if (this.precision !== undefined) {
        const val = this.toPrecision(value, this.precision);
        return val;
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
// 取消element原有的input框样式
::v-deep .el-input--mini .el-input__inner {
  border: 0px;
  margin: 0;
  padding: 0;
  background-color: transparent;
}
.center {
  padding: 0 5px;
  margin-top: -1px;
}
.is-disabled {
  background-color: #eef0f6;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
