<template>
  <div>
    <div
      :class="{ 'is-disabled': disabled, 'is-active': active }"
      class="x-f"
    >
      <el-input
       style="width:150px"
        ref="input_from"
        v-model="userInputForm"
        :disabled="disabled"
        :placeholder="startPlaceholder"
        @change="handleInputChangeFrom"
      ></el-input>
      <div class="center">
        <span>至</span>
      </div>
      <el-input
       style="width:150px"
        ref="input_to"
        v-model="userInputTo"
        :disabled="disabled"
        :placeholder="endPlaceholder"
        @change="handleInputChangeTo"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputNumberRange",
  props: {
    value: { required: true },

    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 精度参数
    precision: {
      type: Number,
      default: 0,
      validator(val) {
        return val >= 0 && val === parseInt(val, 10);
      },
    },
    startPlaceholder: {
      type: String,
      default: "最小值",
    },
    endPlaceholder: {
      type: String,
      default: "最大值",
    },
  },
  data() {
    return {
      active: false,
      userInputForm: null,
      userInputTo: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newV) {
        if (this.precision !== undefined) {
          const data = Array.isArray(newV) ? newV : [undefined, undefined];
          this.userInputForm =  data?.[0];
          this.userInputTo =  data?.[1];
        }
      },
      deep: true,
    },
  },

  methods: {
    // 根据精度保留数字
    toPrecision(num, precision) {
      if (precision === undefined) precision = 0;
      return parseFloat(
        Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
      );
    },

    handleBlurFrom(event) {
      this.$emit("blurfrom", event);
      this.active = false;
    },

    handleFocusFrom(event) {
      this.active = true;
      this.$emit("focusfrom", event);
    },

    handleBlurTo(event) {
      this.active = false;
      this.$emit("blurto", event);
    },

    handleFocusTo(event) {
      this.active = true;
      this.$emit("focusto", event);
    },

    handleInputFrom(value) {
      this.$emit("inputfrom", value);
      // this.userInputFrom = value
    },

    handleInputTo(value) {
      this.$emit("inputto", value);
      // this.userInputTo = value
    },

    // from输入框change事件
    handleInputChangeFrom() {
      this.$emit("input", [this.userInputForm, this.userInputTo]);
      this.$emit("changefrom", this.userInputForm);
    },

    // to输入框change事件
    handleInputChangeTo() {
      this.$emit("input", [this.userInputForm, this.userInputTo]);
      this.$emit("changeto", this.userInputTo);
    },

    // 设置成精度数字
    setPrecisionValue(value) {
      if (this.precision !== undefined) {
        const val = this.toPrecision(value, this.precision);
        return val;
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.center {
  padding: 0 5px;
  margin-top: -1px;
}
.is-disabled {
  background-color: #eef0f6;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
