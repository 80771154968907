<template>
  <div>
    <el-form class="el-form-myde" :inline="true" size="mini">
      <template v-for="(item, index) in buttons">
        <el-form-item v-if="!item.right" :key="item.label + index">
          <PrintButton
            v-if="item && item.print"
            :printTemplate="item"
            :check="tableConfig.check"
          />
          <el-dropdown
            v-else-if="item.btnType === 'dropdown'"
            split-button
            :type="item.type || 'warning'"
            :icon="item.icon || 'el-icon-circle-check'"
            :placement="item.placement || 'bottom'"
            :trigger="item.trigger || 'click'"
            @click="handleEvent(item)"
            @command="handleEvent"
            size="mini"
          >
            {{ item.label }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="btn"
                v-for="(btn, i) in item.other"
                :key="i + btn.click"
              >
                {{ btn.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-else
            :type="item.type"
            :plain="item.plain"
            :icon="item.icon"
            :roun="item.round"
            :disabled="
              typeof item.disabled === 'function' ? item.disabled() : item.disabled
            "
            @click="handleEvent(item)"
          >
            {{ item.label }}
          </el-button>
        </el-form-item>
      </template>
      <!--   typeof item.disabled === 'function' && !item.noDisabled
                  ? item.disabled()
                  : item.disabled && !item.noDisabled -->
      <div v-if="buttons" style="float: right">
        <template v-for="item in buttons">
          <el-form-item v-if="item.right" :key="item.label">
            <el-button
              :type="item.type"
              :plain="item.plain"
              :icon="item.icon"
              :roun="item.round"
              @click="handleEvent(item)"
            >
              {{ item.label }}
            </el-button>
          </el-form-item>
        </template>
      </div>
    </el-form>
    <PrintTemplateDetail
      v-if="printConfig"
      ref="PrintTemplateDetail"
      :isEdit="false"
      :printConfig="printConfig"
      style="
        position: fixed;
        left: -99999px;
        top: -99999px;
        z-index: 999999;
        width: 100vw;
        height: 100vh;
      "
    />
    <Dialog :options.sync="dialogOptions" @handleEvent="dialogEvent" />
  </div>
</template>
<script>
import { deepCopy } from "@/utils";
import { getTemplateList } from "@/api/system/system/printTemplate";
import printConfig from "@/components/tablePage/tableButtons/printConfig.js";

export default {
  name: "TabelPageTableTopButton",
  components: {
    Dialog: () => import("@/components/Dialog"),
    PrintButton: () => import("@/components/tablePage/tableButtons/print.vue"),
    PrintTemplateDetail: () => import("@/views/system/custom/printTemplate/detail"),
  },
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    tableConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      routerPath: "",
      printConfig: null,
      dialogOptions: {
        type: "Collection",
      }, // 一些弹窗配置
    };
  },
  async mounted() {
    this.loadPrint();
  },
  methods: {
    async loadPrint() {
      this.routerPath = printConfig[this.$route.path]
        ? this.$route.path
        : this.$route.meta?.activeMenu;
      const config = printConfig[this.routerPath];
      if (config) {
        const printIndex = this.buttons?.findIndex?.((x) => x.print);
        if (printIndex >= 0) {
          this.$set(this.buttons[printIndex], "print", {
            ...this.buttons[printIndex].print,
            data: null,
            config,
          });
          try {
            const res = await getTemplateList({
              pageNum: 1,
              pageSize: 999999,
              isStop: false,
              routerPath: this.routerPath,
            });
            this.$set(this.buttons[printIndex], "print", {
              ...this.buttons[printIndex].print,
              data: res?.rows,
              config,
            });
          } catch (error) {
            this.$set(this.buttons[printIndex], "print", {
              ...this.buttons[printIndex].print,
              data: [],
              config,
            });
          }
        }
      }
    },
    dialogEvent(type) {
      if (type === "dialogChange") {
        this.$emit("handleEvent", "getList"); // 刷新列表
      }
    },
    async handleEvent(e = {}, row) {
      console.log("到这里了嘛", e, row);
      const { num, click, alertText, collectionDialog, right, noRule, print } = e;
      if (
        !["add", "refresh", "reset", "menu", "recycle", "delRecovery"].includes(click) &&
        click?.indexOf("add-") !== 0 &&
        !noRule &&
        !collectionDialog &&
        !right &&
        !this.tableConfig?.check?.length
      ) {
        return this.$message.warning("请选择要执行的数据");
      }
      if (num && this.tableConfig?.check?.length > num) {
        return this.$message.warning(`当前功能至多可选${num}个数据`);
      }
      if (alertText) {
        const content = typeof alertText === "function" ? alertText() : alertText;
        return this.$confirm(content, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$emit("handleEvent", click);
          })
          .catch(() => {});
      }
      // 一些弹窗功能 例如回收站
      if (collectionDialog) {
        const data = { ...deepCopy(this.tableConfig), ...e.collectionDialog };
        data.height = 450;
        data.hideCard = true;
        data.dcollection = true;
        if (!collectionDialog.search && data.search?.length) {
          data.search = data.search
            .filter((x) => x.isRestore)
            .map((x) => ({
              ...x,
              isLeft: true,
              tip: x.type === "button" ? "搜索" : x.tip,
            }));
        }
        data.check = [];
        data.list = [];
        data.columns = data.columns?.filter((x) => !["button"].includes(x.type));
        delete data.tableTitle;
        delete data.pagination;
        delete data.tree;
        delete data.tableTree;
        delete data.body;
        delete data.buttons;
        delete data.summary;
        if (data.tabsColumns?.length) {
          data.tabsColumns = data.tabsColumns
            .filter((x) => !x.isHideRestore)
            .map((x) => {
              delete x.tableTitle;
              delete x.pagination;
              delete x.tree;
              delete x.tableTree;
              delete x.body;
              delete x.buttons;
              delete x.summary;
              return x;
            });
        }
        console.log("ssssss", data);
        this.dialogOptions = {
          width: "80vw",
          show: true,
          title: "回收站",
          type: "Collection",
          data,
        };
        return;
      }
      this.$emit("handleEvent", e?.click ?? e, row);
    },
  },
};
</script>
<style lang="scss" scoped>
.table-button-popper {
  padding: 0;
  .table-button-popper-content {
    text-align: center;
    margin: 0;
    padding: 4px 0;
    .table-button-popper-item {
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      &:hover {
        background-color: #e8f4ff;
        color: #46a6ff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.el-form-myde {
  background-color: #f8f8f9;
  padding: 8px 0;
  margin-bottom: 10px;
  min-height: 48px;
  clear: both;

  ::v-deep .el-form-item {
    &:last-child {
      margin-right: 0;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
  height: 28px;
}
.el-dropdown {
  //审核按钮
  &.audit {
    margin-left: 10px;
    margin-right: 5px;
    ::v-deep.el-button-group > .el-button {
      background-color: #3cbf1d !important;
      color: #fff;
    }
    ::v-deep.el-button-group > .el-button:hover {
      background-color: #60c947 !important;
    }
  }
  //启用按钮
  &.startUsing {
    margin-left: 5px;
    margin-right: 10px;
    ::v-deep.el-button-group > .el-button {
      background-color: #ff8a00 !important;
      color: #fff;
    }
    ::v-deep.el-button-group > .el-button:hover {
      background-color: #f9a13a !important;
    }
  }
}
</style>
<style lang="scss" scoped></style>
